import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import styled from "styled-components"
import Image from "gatsby-image"
// import TableOfContents from "../components/Banner/TableOfContents"
import Banner from "../components/Banner"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../components/SEO"
const PostTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: { title, category, image, date, description, keywords },
      body,
      tableOfContents,
    },
  } = data

  return (
    <Layout>
      {image.childImageSharp ? (
        <SEO
          title={title}
          image={image.childImageSharp.fluid.src}
          description={description}
        />
      ) : (
        <SEO
          title={title}
          image={image}
          description={description}
          keywords={keywords}
        />
      )}
      <Hero />
      <Wrapper>
        <article>
          {image.childImageSharp ? (
            <Image fluid={image.childImageSharp.fluid} alt={keywords[0]} />
          ) : (
            <img src={image} width="100%" height="450" alt={keywords[0]} />
          )}

          <div className="post-info">
            <span>{category}</span>
            <h2>{title}</h2>
            <p>{date}</p>
            <div className="underline"></div>
          </div>
          <MDXRenderer>{body}</MDXRenderer>
        </article>
        <article>
          {/* https://scottspence.com/2020/02/13/smooth-scroll-toc-gatsby/#clone-the-gatsby-default-starter-with-styled-components 
          https://nickymeuleman.netlify.app/blog/table-of-contents*/}
          {/* {mdx?.tableOfContents?.items && ( */}
          {/* <TableOfContents items={tableOfContents} /> */}
          {/* )} */}
          <Banner post={"post"} tableOfContents={tableOfContents} />
        </article>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date(formatString: "MMM Do, YYYY")
        image
        description
        keywords
      }
      body
      tableOfContents(maxDepth: 3)
      children {
        ... on ImageSharp {
          id
          fluid {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
    }
  }
`

// {
//   childImageSharp {
//     fluid {
//       src
//       ...GatsbyImageSharpFluid
//     }
//   }

const Wrapper = styled.section`
  width: 85vw;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 4rem;
  .post-info {
    margin: 2rem 0 4rem 0;
    text-align: center;
    span {
      background: var(--clr-primary-5);
      color: var(--clr-white);
      border-radius: var(--radius);
      padding: 0.25rem 0.5rem;
      text-transform: uppercase;
      letter-spacing: var(--spacing);
    }
    h2 {
      margin: 1.25rem 0;
      font-weight: 400;
    }
    p {
      color: var(--clr-grey-5);
    }
    .underline {
      width: 5rem;
      height: 1px;
      background: var(--clr-grey-9);
      margin: 0 auto;
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 992px) {
    & {
      width: 92vw;
    }
  }
  @media (min-width: 1170px) {
    & {
      display: grid;
      grid-template-columns: 1fr 200px;
      column-gap: 4rem;
    }
  }
`

export default PostTemplate
